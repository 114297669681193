.bg1 {
  background-image: url("./../../assets/1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg1::after {
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-size: cover;
  background-image: url("./../../assets/1.jpg");
}
.text {
  box-shadow: 0 0 2px rgba(128, 128, 128, 0.9);
  border-radius: 10px;
  justify-content: center;
  color: #96216b;
  width: 70%;
  background-color: white;
  margin-top: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.registration1 {
  transition: 0.3s all ease-in-out;
  background-color: #fefefe;
  justify-content: center;
  align-items: center;
}
.reg-container1 {
  display: flex;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
}
p {
  font-size: 36px;
  color: #96216b;
  font-weight: 600;
  font-kerning: 10px;
}
form {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 90vh;
  height: 500px;
}

select {
  margin-left: 5px;
  height: 2.5rem;
  border-radius: 5px;
  background-color: gainsboro;
  border: none;
  width: 41%;
  padding-left: 10px;
}

select:focus {
  outline: #96216b;
  border: 1px solid #96216b;
}

input {
  margin-left: 5px;
  height: 2.5rem;
  border-radius: 5px;
  background-color: gainsboro;
  border: none;
  width: 65%;
  padding-left: 10px;
}

input[type="number"] {
  width: 40px;
  margin: 0;
  margin-right: 20px;
}
.age {
  display: inline;
}

.input-city {
  width: 46%;
  margin: 0;
}

input:focus {
  outline: #96216b;
  border: 1px solid #96216b;
}

label {
  font-size: 18px;
  color: #96216b;
  font-weight: 400;
  padding-right: 10px;
}

.regComplete {
  display: flex;
  background-color: white;
  flex-direction: column;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-top: 1rem;
  height: 2rem;
  padding: 1rem;
}
.form-inputs {
  margin-top: 20px;
}
.RegisterBtn {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: #96216b;
  height: 50px;
  border: none;
  border-radius: 11px;
  color: white;
  font-size: 30px;
  width: 70%;
  font-weight: 600;
  /* add hover effect for button*/
}
button:hover {
  background-color: white;
  color: #96216b;
  border: 2px solid #96216b;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .text {
    font-size: 30px;
    width: 100%;
  }
  form {
    width: 90%;
    height: auto;
  }
  select {
    width: 30%;
  }
  input[type="number"] {
    margin-left: 5px;
    height: 2.5rem;
    border-radius: 5px;
    background-color: gainsboro;
    border: none;
    width: 65%;
    padding-left: 10px;
  }
  .age {
    display: block;
  }
  .input-city {
    margin-left: 10px;
    height: 2.5rem;
    border-radius: 5px;
    background-color: gainsboro;
    border: none;
    width: 65%;
    padding-left: 10px;
    margin-top: 20px;
  }
}
